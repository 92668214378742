<template>
    <!--内容-->
    <div class="page-content-x">
        <!--标题-->
        <el-row>
            <el-col :span="12"><h3>门店：{{ storeinfo.store_name }}</h3></el-col>
            <el-col :span="12">
                <div class="top-operation-button">
                    <el-button v-if="is_auth('store.lesson.issave')"
                               icon="el-icon-plus"
                               size="medium" type="primary" @click="add_page(null)">添加
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
                v-loading="loading"
                :data="tableData"
                border
                size="medium"
                style="width: 100%">
            <el-table-column
                    prop="company_name"
                    label="所属公司"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="lesson_name"
                    label="名称"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="lesson_type"
                    label="类型"
                    width="180">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.lesson_type===1" size="mini">团课</el-tag>
                    <el-tag v-if="scope.row.lesson_type===2" size="mini">训练营</el-tag>
                    <el-tag v-if="scope.row.lesson_type===3" size="mini">私教</el-tag>
                    <el-tag v-if="scope.row.lesson_type2===1" size="mini" type="success">大团课</el-tag>
                    <el-tag v-if="scope.row.lesson_type2===2" size="mini" type="success">小团课</el-tag>
                    <el-tag v-if="scope.row.lesson_type2===3" size="mini" type="success">小团私</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="price_indication"
                    label="参考价格">
                <template slot-scope="scope">
                    ￥{{ scope.row.price_indication }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="price_store"
                    label="门店价格"
                    width="180">
                <template slot-scope="scope">
                    ￥{{ scope.row.price_store }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="quota"
                    label="上课人数"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="lesson_duration"
                    label="时长（分钟）"
                    width="180">
                <template slot-scope="scope">
                    {{ scope.row.lesson_duration }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="state"
                    label="状态"
                    width="180">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.state===1" type="success">正常</el-tag>
                    <el-tag size="mini" v-if="scope.row.state===2" type="warning">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="arrange_sum"
                    label="排课次数"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="capacity_sum"
                    label="总容纳人数"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="subscribe_number"
                    label="总预约次数"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="person_time"
                    label="总服务人次"
                    width="180">
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="150">
                <template slot-scope="scope">
                    <el-button
                            v-if="is_auth('store.classroom.issave')"
                            @click="add_page(scope.row)"
                            size="mini">编辑
                    </el-button>
                    <el-button
                            v-if="is_auth('store.lesson.isenable') && scope.row.state===2"
                            @click="operation_tip(scope.row.store_lesson_uuid,scope.row.lesson_name,'isenable')"
                            type="primary" size="mini">启用
                    </el-button>
                    <el-button
                            v-if="is_auth('store.lesson.isdisable') && scope.row.state===1"
                            @click="operation_tip(scope.row.store_lesson_uuid,scope.row.lesson_name,'isdisable')"
                            type="warning" size="mini">禁用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="height: 20px;"></div>
        <!--分页-->
        <el-pagination
                @current-change="getlist"
                :page-size="this.env.pageSize"
                :pager-count="7"
                background
                layout="prev, pager, next, total"
                :current-page.sync="page"
                :total="count">
        </el-pagination>
        <!--编辑-->
        <el-dialog v-loading="loading"
                   v-if="is_auth('store.lesson.issave')"
                   title="编辑课程"
                   :visible.sync="editPage"
                   width="50%"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
        >
            <el-row>
                <el-form size="medium" ref="form" :label-width="this.env.label_width">
                    <el-col :span="24">
                        <el-form-item label="选择课程">
                            <el-select v-if="this.Tool.is_empty(info.store_lesson_uuid)" v-model="info.lesson_uuid"
                                       @change="selectlesson" placeholder="请选择课程"
                                       style="width: 100%">
                                <el-option
                                        v-for="item in lesson_list"
                                        :key="item.lesson_uuid"
                                        :label="item.lesson_name"
                                        :value="item.lesson_uuid">
                                    <span style="float: left">{{ item.lesson_name }}</span>
                                    <span style="float: right; color: #409EFF; font-size: 13px">
                                        {{ item.lesson_type_name }}
                                    </span>
                                </el-option>
                            </el-select>
                            <span v-else>{{ info.lesson_name }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col v-show="info.lesson_type!==0" :span="24">
                        <el-form-item label="课程类型">
                            <el-tag v-if="info.lesson_type===1" size="mini">团课</el-tag>
                            <el-tag v-if="info.lesson_type===2" size="mini">训练营</el-tag>
                            <el-tag v-if="info.lesson_type===3" size="mini">私教</el-tag>
                            <el-tag v-if="info.lesson_type2===1" size="mini" type="success">大团课</el-tag>
                            <el-tag v-if="info.lesson_type2===2" size="mini" type="success">小团课</el-tag>
                            <el-tag v-if="info.lesson_type2===3" size="mini" type="success">小团私</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col v-show="!this.Tool.is_empty(info.price_indication)" :span="24">
                        <el-form-item label="参考价格.">
                            {{ info.price_indication }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="门店价格">
                            <el-input v-model="info.price_store"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="上课人数">
                            <el-input v-model="info.quota"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row style="margin: 10px 0px;">
                <el-button size="medium" type="primary" @click="save">确 定</el-button>
                <el-button size="medium" @click="editPage = false">取 消</el-button>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '门店课程',
            loading: true,          // 加载状态
            count: 0,               // 数据总条数
            tableData: [],          // 列表内容
            page: 1,                // 当前页数
            search: {},             // 搜索条件
            storeinfo: {},          // 门店信息
            info: {},               // 编辑信息
            editPage: false,        // 编辑页面的展示
            store_uuid: '',         // 门店uuid
            lesson_list: [],        // 获取课程信息
        }
    },
    // 创建
    created() {
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getlesso() // 获取课程列表
            let store_uuid = this.$route.query.store_uuid
            if (store_uuid !== undefined) {
                this.store_uuid = store_uuid
                this.getinfo()  // 获取门店信息
                this.getlist()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({path: '/store/store'})    // 返回列表
                    }
                });
            }
        },
        // 门店详情
        getinfo() {
            let postdata = {
                api_name: "store.store.getinfo",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.storeinfo = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        //搜索 - 暂时无用
        /* is_search() {
             this.page = 1
             this.getlist();
         },*/
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "store.lesson.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                store_uuid: this.store_uuid
            }
            // 非必须参数（搜索条件）-暂时无用
            // Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(store_lesson_uuid = '', lesson_name = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + lesson_name + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + lesson_name + '】？'
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(store_lesson_uuid, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(store_lesson_uuid = '', operation = '') {
            let postdata = {
                api_name: "store.lesson." + operation,
                token: this.Tool.get_l_cache('token'),
                store_lesson_uuid: store_lesson_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加页面显示
        add_page(row = {}) {
            if (!this.Tool.is_empty(row)) {
                this.info = row
            } else {
                this.info = {
                    lesson_uuid: '',
                    lesson_type: 0,             // 1团课，2训练营
                    lesson_type2: 0,            // 1大团课，2小团课，3小团私
                    price_indication: '',       // 参考价格
                    price_store: '',            // 门店价格
                    quota: '',                   //上课人数
                }
            }
            this.editPage = true    // 显示页面
        },
        //获取课程可选列表
        getlesso() {
            let postdata = {
                api_name: "store.lesson.getlesson",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.lesson_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'store.lesson.issave',
                token: this.Tool.get_l_cache('token'),
                price_store: this.info.price_store,
                quota: this.info.quota,
            }

            // 判断是否为修改
            if (!this.Tool.is_empty(this.info.store_lesson_uuid)) {
                postdata.store_lesson_uuid = this.info.store_lesson_uuid
            } else {
                postdata.lesson_uuid = this.info.lesson_uuid
                postdata.lesson_type = this.info.lesson_type
                postdata.lesson_type2 = this.info.lesson_type2
                postdata.store_uuid = this.store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        selectlesson(lesson_uuid) {
            for (let x of this.lesson_list) {
                if (x.lesson_uuid === lesson_uuid) {
                    this.info.price_indication = x.price_indication
                    this.info.price_store = x.price_indication
                    this.info.lesson_type = x.lesson_type
                    this.info.lesson_type2 = x.lesson_type2
                    break
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tag_colour {
    height: 28px;
    /*background: #2c3e50;*/
    border: 1px solid;
    text-align: center;
    line-height: 28px;
}

.tag_colour-tag {
    border: 0px;
}
</style>
