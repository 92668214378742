<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/store/store' }">门店列表</el-breadcrumb-item>
                <el-breadcrumb-item>门店编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: 20px;"></div>
        <!--内容-->
        <div class="page-content-x">
            <div class="page-content-x">
                <el-tabs v-model="activeName" @tab-click="onTabs">
                    <el-tab-pane label="基本信息" name="info">
                        <!--标题-->
                        <el-row>
                            <el-col :span="12">
                                <h3>门店编辑</h3>
                            </el-col>
                        </el-row>
                        <div style="height: 20px;"></div>
                        <!--详情-->
                        <el-row>
                            <el-col :span="16">
                                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                                    :label-width="this.env.label_width">
                                    <el-form-item label="门店名称">
                                        <el-input v-model="form.store_name"></el-input>
                                        <!--                        <el-checkbox v-model="oss_arrangement">允许平台排课</el-checkbox>-->
                                    </el-form-item>
                                    <el-form-item label="门店视频">
                                        <Qnupload v-model="form.store_video" type="video" />
                                        <el-checkbox v-model="store_video_carousel">门店视频放到轮播图</el-checkbox>
                                    </el-form-item>
                                    <el-form-item label="门店照片">
                                        <Qnupload v-model="form.store_photo" :sum="5" :isclipper="true" :compress="false"
                                            :fixedNumber="[4, 3]" />
                                        <span class="form-tip">图片尺寸1024*768 或 宽:高≈4:3</span>
                                    </el-form-item>
                                    <el-form-item label="运动品类">
                                        <div class="category-select">
                                            <el-select @change="add_tag" v-model="select_tag" filterable
                                                placeholder="请选择运动品类" style="width: 100%">
                                                <el-option v-for="item in tag_list" :key="item.tag_uuid"
                                                    :label="item.tag_name" :value="item.tag_name + '|' + item.tag_colour">
                                                </el-option>
                                            </el-select>
                                            <div class="category-select-child" @click="isMotionCategory = true"></div>
                                        </div>
                                        <div class="form-tag_colour-div">
                                            <el-tag :key="item.id" v-for="item in this.form.motion_category_two"
                                                class="form-tag_colour-tag" effect="dark" closable @close="tagClose(item)">
                                                {{ item.name }}
                                            </el-tag>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="负责人">
                                        <el-input v-model="form.responsible"></el-input>
                                    </el-form-item>
                                    <el-form-item label="门店电话">
                                        <el-input v-model="form.store_phone"></el-input>
                                    </el-form-item>
                                    <el-form-item label="门店微信">
                                        <Qnupload v-model="form.store_wx" :sum="1" />
                                        <span class="form-tip">门店负责人微信二维码</span>
                                    </el-form-item>
                                    <el-form-item label="地址选择">
                                        <Region ref="region" v-model="form.region_code" :onlyallow="1"
                                            v-on:region="getregion" />
                                    </el-form-item>
                                    <el-form-item v-if="!this.Tool.is_empty(form.city)" label="地址">
                                        <el-input v-model="form.address" @change="getposition"></el-input>
                                    </el-form-item>
                                    <el-form-item label="地图">
                                        <Amap ref="amap_edit" idindex="edit" style="height: 600px;" />
                                        <span v-if="!this.Tool.is_empty(this.form.longitude)">经纬度:&nbsp;&nbsp;
                                            <span>{{ this.form.longitude }}</span>&nbsp;,&nbsp;<span>{{ this.form.latitude
                                            }}</span>
                                        </span>
                                    </el-form-item>
                                    <el-form-item label="交通信息">
                                        <el-input v-model="form.trafficInfo" type="textarea"
                                            :autosize="{ minRows: 2, maxRows: 4 }" maxlength="200"
                                            show-word-limit></el-input>
                                    </el-form-item>
                                    <el-form-item label="门店简介">
                                        <el-input v-model="form.introduce" type="textarea"
                                            :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
                                            show-word-limit></el-input>
                                        <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
                                        <!--                        <Wangeditor ref="wangeditor_introduce" v-model="form.introduce" idindex="introduce"-->
                                        <!--                                    seewWidth="50%"/>-->
                                    </el-form-item>
                                    <el-form-item label="注意事项">
                                        <el-input v-model="form.attention" type="textarea"
                                            :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
                                            show-word-limit></el-input>
                                        <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
                                        <!--                        <Wangeditor ref="wangeditor_attention" v-model="form.attention" idindex="attention"-->
                                        <!--                                    seewWidth="50%"/>-->
                                    </el-form-item>
                                    <el-form-item v-if="!is_empty(this.store_uuid) && is_auth('store.store.usecouponset')"
                                        label="允许使用优惠券">
                                        <el-switch v-model="form.use_coupon" :active-value="1" :inactive-value="0"
                                            @change="use_coupon_set"></el-switch>
                                        <br>
                                        <span class="form-tip">关闭后买家在您的门店将无法使用平台发放的优惠券</span>
                                    </el-form-item>
                                    <el-form-item v-else label="允许使用优惠券">
                                        <el-switch v-model="form.use_coupon" :active-value="1"
                                            :inactive-value="0"></el-switch>
                                        <br>
                                        <span class="form-tip">关闭后买家在您的门店将无法使用平台发放的优惠券</span>
                                    </el-form-item>

                                    <div id="consumer_card_set_place" v-if="form.consumer_card_extend >= 1">
                                        <el-form-item
                                            v-if="!is_empty(this.store_uuid) && is_auth('store.store.consumercardset')"
                                            label="允许使用消费卡">
                                            <el-switch v-model="form.use_consumer_card" :active-value="1"
                                                :inactive-value="0" @change="use_consumer_card_set"></el-switch>
                                            <br>
                                            <span class="form-tip">关闭用户将不允许使用消费卡购买</span>
                                        </el-form-item>
                                        <el-form-item label="消费卡产品">
                                            <el-checkbox-group v-model="form.product_line_all">
                                                <el-checkbox :label="1" name="type">团课</el-checkbox>
                                                <el-checkbox :label="2" name="type">训练营</el-checkbox>
                                                <el-checkbox :label="3" name="type">私教</el-checkbox>
                                                <el-checkbox :label="4" name="type">商品</el-checkbox>
                                                <el-checkbox :label="5" name="type">活动</el-checkbox>
                                                <el-checkbox :label="6" name="type">赛事</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>

                                    <el-form-item>
                                        <el-button v-if="is_auth('store.store.issave')" size="medium" type="primary"
                                            @click="save">保存
                                        </el-button>
                                        <el-button size="medium" @click="isreturn">返回</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <template v-if="store_uuid">
                        <el-tab-pane v-if="is_auth('store.lesson.getlist')" label="门店课程" name="lesson">
                            <Lesson ref="lesson" />
                        </el-tab-pane>
                        <el-tab-pane v-if="is_auth('store.classroom.getlist')" label="门店教室" name="classroom">
                            <Classroom ref="classroom" />
                        </el-tab-pane>
                        <el-tab-pane label="门店评价" name="comment">门店评价</el-tab-pane>
                        <el-tab-pane v-if="is_auth('store.coach.getlist')" label="门店教练" name="coach">
                            <Coach ref="coach" />
                        </el-tab-pane>
                    </template>
                </el-tabs>
            </div>


        </div>

        <MotionCategory v-model="isMotionCategory" :motion_category_two="form.motion_category_two"
            @selectData="onSelectCategoryData" />
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import Region from '@/components/Region'
import Amap from '@/components/Amap'
import MotionCategory from '@/components/MotionCategory'
import Lesson from './../page/Lesson'
import Classroom from './../page/Classroom'
import Coach from './../page/Coach'

export default {
    components: {
        // Wangeditor,
        Qnupload,
        Region,
        Amap,
        MotionCategory,
        Lesson,
        Classroom,
        Coach,
    },
    data() {
        return {
            activeName: 'info',
            loading: true,
            store_uuid: '',
            tag_list: [], // 标签数据
            select_tag: [], // 选择的标签
            form: {
                store_video: '',
                store_photo: [],
                store_tag: [],
                use_coupon: 1,
                use_consumer_card: 1,
                product_line_all: [],
                motion_category_two: [],
            },
            store_video_carousel: false, // 视频是否加入轮播图
            oss_arrangement: false,          // 允许平台排课
            isMotionCategory: false, // 是否展示运动品类弹窗
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
        this.gettag();
    },
    // 安装
    mounted() {
    },
    methods: {
        // 点击切换页面
        onTabs(tab) {
            if (tab.paneName === 'lesson') {        // 点击门店课程
                this.$refs.lesson.init();
            }
            if (tab.paneName === 'classroom') {     // 点击门店教室
                this.$refs.classroom.init();
            }
            if (tab.paneName === 'coach') {     // 点击门店教练
                this.$refs.coach.init();
            }
        },
        // 判断是否是空
        is_empty(data) {
            return this.Tool.is_empty(data)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let store_uuid = this.$route.query.store_uuid
            if (store_uuid !== undefined) {
                this.store_uuid = store_uuid
                this.getinfo()
            } else {
                this.loading = false
            }
        },
        // 门店详情
        getinfo() {
            let thi = this
            let postdata = {
                api_name: "store.store.getinfo",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                    this.$refs.region.init(json.data.region_code);                  // 地区选择
                    // this.$refs.wangeditor_introduce.append(json.data.introduce);    // 门店简介
                    // this.$refs.wangeditor_attention.append(json.data.attention);    // 注意事项

                    this.store_video_carousel = json.data.store_video_carousel === 1 ? true : false      //视频是否加入轮播图
                    this.oss_arrangement = json.data.oss_arrangement === 1 ? true : false                //是否允许平台排课

                    setTimeout(function () {
                        thi.getposition()           // 地图定点
                    }, 1000);

                    // 判断是否开通了消费卡
                    if (json.data.consumer_card_extend === 1) {
                        thi.consumer_card_set_scrollIntoView()
                    }
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取标签
        gettag() {
            let postdata = {
                api_name: "store.store.gettag",
                token: this.Tool.get_l_cache("token"),
            };
            this.loading = true;
            this.Tool.post_data("merchant", postdata, (json) => {
                this.loading = false;
                if (json.code === 0) {
                    this.tag_list = json.data;
                } else {
                    this.Tool.errormes(json);
                }
            });
        },
        // 地址
        getregion(region_date) {
            this.form.province = region_date.province.region_level
            this.form.city = region_date.city.region_level
            this.form.county = region_date.county.region_level
        },
        // 获取坐标
        getposition() {
            let thi = this
            let address = this.form.city + this.form.address
            this.$refs.amap_edit.address2position(address, (json) => {
                if (json) {
                    thi.form.longitude = json.lng
                    thi.form.latitude = json.lat
                    this.$refs.amap_edit.marker([json.lng, json.lat, thi.form.store_name])
                } else {
                    thi.form.longitude = ''
                    thi.form.latitude = ''
                    this.$message({
                        message: '根据地址查询位置失败', type: 'error',
                        duration: this.env.message_error,
                    });
                }
            });
        },
        // 滑动到消费卡设置
        consumer_card_set_scrollIntoView() {
            // 判断是否直接跳转到指定位置
            if (this.$route.query.toedit == 1) {
                setTimeout(function () {
                    document.getElementById("consumer_card_set_place").scrollIntoView()
                }, 800)
            }
        },
        //保存
        save() {
            let postdata = {
                api_name: "store.store.issave",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);
            postdata.store_video_carousel = this.store_video_carousel ? 1 : 0   // 视频是否加入轮播图
            postdata.oss_arrangement = this.oss_arrangement ? 1 : 0         // 是否允许平台排课
            postdata.motion_category_two = postdata.motion_category_two.map(item => item.id)

            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // 判断是否有门店id，如果有返回详情（编辑的情况），没有返回列表（添加的情况）
                            if (this.Tool.is_empty(this.store_uuid)) {
                                this.isreturn();        // 返回到列表
                            } else {
                                this.$emit('toinfo')    // 返回到详情
                            }
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/store/store' })
        },
        // 是否使用优惠卷
        use_coupon_set(val) {
            let postdata = {
                api_name: "store.store.usecouponset",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid,
                use_coupon: val,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form.issms = val
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 是否使用消费卡
        use_consumer_card_set(val) {
            let postdata = {
                api_name: "store.store.consumercardset",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid,
                use_consumer_card: val,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form.issms = val
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|");
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
            };
            if (!this.tag_exist(tag.tag_name)) {
                this.form.store_tag.push(tag);
            }
            this.select_tag = "";
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.form.store_tag.length > 0) {
                for (let v of this.form.store_tag) {
                    if (tag_name == v.tag_name) {
                        return true;
                    }
                }
                return false;
            } else {
                return false;
            }
        },
        // 删除选中的标签
        tagClose(tag) { this.form.motion_category_two.splice(this.form.motion_category_two.indexOf(tag), 1); },
        // 选中运动品类
        onSelectCategoryData(data) {
            this.form.motion_category_two = data;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.category-select {
    position: relative;
}

.category-select-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
</style>
